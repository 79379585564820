import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from 'axios';
import classnames from "classnames";

import { PageLayout } from "../layouts";
import { AboutPartieBlock, Block, PromoBlock, EventsGrid, GetStartedBlock, TournamentHeat, TournamentLeaderboard } from '../components';


import EventImg from "../images/Clash/clash.svg";
import { INFLUENCER_DATA } from "../mockData/influencer-data";
import "../global/styles/page-styles/tournament-page.scss";

const API_ENDPOINT = process.env.API_ENDPOINT;
const API_ENDPOINT_AVATAR_LIST = process.env.API_ENDPOINT_AVATAR_LIST;

const Clash = () => {
    const [pageData, setPageData] = useState({});
    const [active, setActive] = useState("current");

    const pageLink = "/clash/rules";
    const pageLinkText = "View Tournament Rules";
    const pageSize = 30;
    const tournament = "tournament";

    /**
     *
     * Get Event Data when the component mounts.
     *
     */
    useEffect(() => {
        /**
         * 
         * Hyrdate Events Grid data.
         * Pass in Search Queries as query params.
         * 
         */
        axios.get(`${API_ENDPOINT}?pageSize=${pageSize}&roomType=${tournament}`)
            .then((res) => {
                let captains = [];
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].maxPlayers = "3"
                    res.data[i].time = "September 24th, 3pm PST"
                    res.data[i].prize = "$30,000"
                    captains.push(res.data[i])

                    axios.get(`${API_ENDPOINT_AVATAR_LIST}?roomId=${res.data[i].id}`)
                        .then((res2) => {
                            res.data[i].people = res2.data;
                            setPageData({
                                events: captains
                            });
                        })
                        .catch(error => {
                            console.log('Fetch error :: ', error);
                        })
                }
            })
            .catch(error => {
                console.log('Fetch error :: ', error);
            })
    }, []);

    /**
     * Updates active tab.
     * @param {string} newActive
     */
    const handleToggleClick = (newActive) => {
        if (newActive !== active) {
            setActive(newActive);
        }
    };

    return (
        <>
            <Helmet>
                <title>Partie | Clash</title>
                <html lang="en" />
                <meta name="description" content="The Partie Community Clash" />
            </Helmet>
            <PageLayout className="tournament-page" hero>
                <Block hero lazy>
                    <div className="partie-block__column">
                        <h2 className="partie-block__title">
                            The Partie
                            Community
                            Clash
                        </h2>
                        <div className="partie-block__description">
                            Compete alongside your favorite creator in Call Of Duty®: Warzone™ for a shot at a $30,000 prize pool! To enter, please follow the steps below: <br />
                            <br />
                            <ol>
                                <li>Click "Join Now" below and Sign Up / Log In</li>
                                <li><a href="https://app.partie.com/subscription">Subscribe to Partie Plus</a> using code CLASH</li>
                                <li>Check Your Email for Registration Form* and Submit!</li>
                            </ol>
                            <p className="notice">*Form will be sent to your registered email within 24-48 hours.</p>
                        </div>
                        <div className="partie-block__cta">
                            <a className="btn" href="https://app.partie.com//partie/tournament/display/a19956e5-f532-454e-8588-8f0dcbaaaa78">
                                Join now
                            </a>
                        </div>
                    </div>
                    <div className="partie-block__column">
                        <img src={EventImg} alt="Clash Event" />
                    </div>
                </Block>
                <AboutPartieBlock />
                {INFLUENCER_DATA?.promo && <PromoBlock data={INFLUENCER_DATA.promo} pageLink={pageLink} pageLinkText={pageLinkText} />}
                <Block className="events-block tournament-events">
                    <nav className="tournament-events__nav">
                        <button
                            className={classnames("tournament-events__toggle-btn", {
                                "tournament-events__toggle-btn--active": active === "current",
                            })}
                            onClick={() => handleToggleClick("current")}
                        >
                            Game Leaderboards
                        </button>
                        <button
                            className={classnames("tournament-events__toggle-btn", {
                                "tournament-events__toggle-btn--active": active === "upcoming",
                            })}
                            onClick={() => handleToggleClick("upcoming")}
                        >
                            Overall Leaderboard
                        </button>
                    </nav>
                    <div className="tournament-events__content">
                        <h2 className="events-block__title">Call Of Duty®: Warzone™ Community Clash</h2>
                        {/* <EventsGrid events={pageData.events} length={pageSize}/> */}
                        {active === "current" && <TournamentHeat />}
                        {active === "upcoming" && <TournamentLeaderboard />}
                    </div>
                </Block>
                <GetStartedBlock />
            </PageLayout>
        </>
    );
};

export default Clash;